import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <div class="overflow-hidden">
      <StaticImage
        src="../images/1.jpg"
        alt="CondeHouse Style 2021 Main Image"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />

      <div class="p-10 shadow-2xl leading-8">
        <span class="chsbox">6/16 WED - 6/20 SUN</span>
        <h2 class="cacco">CondeHouse Style 2021</h2>
        <p>地球環境に配慮した企業活動と、感性豊かなデザインの具現化をテーマに、創業50周年を迎えた2018年より「リブランディング」に取り組んできました。その成果のひとつが、木の家具がもたらす心地よい暮らしを表現する「18のライフスタイルシーン」の提案 。リビング、ダイニングをはじめこのところ需要が高まるワークスペースなどをトータルコーディネートし、新作とともにご覧いただきます。</p>
      </div>
    </div>
    <div class="overflow-hidden">
      <StaticImage
        src="../images/scene1.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem` }}
        className="topimg magnify"
      />
      <div class="p-10 shadow-2xl leading-8">
        <span class="chsbox">6/16 WED - 6/20 SUN</span>
        <h2 class="cacco">CH ショップリフレッシュ</h2>
        <p>木の家具がもたらす心地よい暮らしを表現する「18のライフスタイルシーン」の提案。リビング、ダイニングをはじめ需要が高まるワークスペースなど、木の家具がもたらす心地よい暮らしのトータルコーディネートをご覧ください。</p>
      </div>
    </div>
    <div class="overflow-hidden">
      <StaticImage
        src="../images/factory.jpg"
        alt="CondeHouse Style 2021 Scene Image 1"
        style={{ marginBottom: `1.45rem`, width: '100%' }}
        className="topimg magnify"
      />
      <div class="p-10 shadow-2xl leading-8">
        <span class="chsbox">6/16 WED - 6/19 SAT</span>
        <h2 class="cacco">CH ファクトリーツアー</h2>
        <p>製造ラインを巡る「ファクトリーツアー」、木のぬくもりとものづくりを体験できる「ワークショップ」にご参加ください。</p>
      </div>
    </div>
    <div class="p-10 shadow-2xl leading-8">
      <h2 class="cacco">New Model</h2>
      <p>ドイツ人デザイナーのミヒャエル・シュナイダー氏による洗練されたデザインの新作チェアー「 SUN 」。</p>
      <p>人気のチャレンジシリーズのデザイナ ー、ホフマン・カーライス氏によるフォーマル感漂う新作ダイニング。</p>
      <p>カンディハウスのシステムファニャーam が生まれ変わります。新たな仕様が加わる新モデルをお楽しみに。</p>
      <p>新型コロナウイルス感染状況など、都合により予定が変更になる場合がございます。ニュースやメルマガ等で情報を更新してまいりますので、最新情報をチェックください。</p>
      <p>【安全対策について】</p>
      <p>※新型コロナウイルス感染対策のため、参加人数を定め、安全対策を万全とした上で、開催いたします。</p>
      <p class="smalltext">※イベント参加は全て予約制となります。申し込みについては5/15より受付開始</p>
      <p><Link to="http://eepurl.com/hfi_5n" className="ch-button shadow-lg">CondeHouse Style 2021 来場予約</Link></p>
      <Link to="/page-2/">別ページ</Link> <br />
    </div>
  </Layout>
)

export default IndexPage
